import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { useHandleSignIn } from "./AppContextProvider";

export type SignInButtonProps = ButtonProps;

function SignInButtonBase(
  props: SignInButtonProps,
  ref: React.Ref<HTMLButtonElement>,
) {
  const handleSignIn = useHandleSignIn();
  return (
    <Button
      ref={ref}
      color={"primary"}
      onClick={handleSignIn}
      variant={"outlined"}
      {...props}
    >
      {props.children ?? "Войти"}
    </Button>
  );
}

export const SignInButton = React.forwardRef(SignInButtonBase);

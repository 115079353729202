import {
  Link,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  MenuProps,
} from "@mui/material";
import * as React from "react";
import { MenuItemDescr } from "../common/MenuItemDescr";
import { useNavigate } from "../core";

export type DropdownMenuProps = Omit<MenuProps, "open"> & {
  items: MenuItemDescr[];
};

export function DropdownMenu(props: DropdownMenuProps): JSX.Element {
  const { PaperProps, MenuListProps, items, ...other } = props;

  const navigate = useNavigate();

  function handleClick(event: React.MouseEvent<HTMLAnchorElement>): void {
    props.onClose?.(event, "backdropClick");
    navigate(event); // TODO: check whether it is actually needed
  }

  return (
    <Menu
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      slotProps={{
        paper: { ...PaperProps, sx: { ...PaperProps?.sx, width: 450 } },
      }}
      MenuListProps={{ ...MenuListProps, dense: true }}
      {...other}
    >
      {items.map((item) => (
        <MenuItem
          key={item.id}
          component={Link}
          href={item.href}
          onClick={handleClick}
        >
          <ListItemIcon sx={{ minWidth: 40 }}>{item.icon}</ListItemIcon>
          <ListItemText primary={item.text} />
        </MenuItem>
      ))}
    </Menu>
  );
}

import { Button, ExtendButtonBaseTypeMap, SxProps, Theme } from "@mui/material";
import {
  OverridableTypeMap,
  OverrideProps,
} from "@mui/material/OverridableComponent";
import React from "react";
import { MenuItemDescr } from "../common/MenuItemDescr";
import { DropdownMenu } from "./DropdownMenu";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

export type DropdownMenuButtonProps = Omit<
  OverrideProps<ExtendButtonBaseTypeMap<OverridableTypeMap>, "a">,
  "children" | "component" | "color"
> & {
  text: string;
  href?: string;
  items?: MenuItemDescr[];
  color?: "primary" | "secondary" | undefined;
  sx?: SxProps<Theme>;
};

export const DropdownMenuButton = (props: DropdownMenuButtonProps) => {
  const menuAnchorRef = React.createRef<HTMLAnchorElement>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>();

  return (
    <>
      <Button
        component="a"
        variant="contained"
        color={props.color ?? "primary"}
        href={props.href}
        ref={menuAnchorRef}
        onClick={() => setAnchorEl(menuAnchorRef.current)}
        sx={{ textTransform: "none" }}
        {...props}
      >
        {props.text}
      </Button>
      {!!props.items && props.items.length !== 0 && (
        <DropdownMenu
          anchorEl={anchorEl}
          onClose={() => setAnchorEl(null)}
          slotProps={{
            paper: { sx: { mt: "8px" } },
          }}
          items={props.items}
        />
      )}
    </>
  );
};

import React from "react";
import { AppBase, AppBaseProps } from "./AppBase";
import { AppContextProvider } from "./AppContextProvider";
import { AppToolbar } from "./AppToolbar";
import { PreloadedQuery } from "react-relay";
import { type AppContextProviderQuery as AppContextProviderQueryType } from "../queries/AppContextProviderQuery.graphql";

export type AppProps = Omit<AppBaseProps, "children"> & {
  initialQueryRef: PreloadedQuery<
    AppContextProviderQueryType,
    Record<string, unknown>
  >;
};

export const App = (props: AppProps): JSX.Element => {
  return (
    <AppBase
      relay={props.relay}
      config={props.config}
      history={props.history}
      resolveRoute={props.resolveRoute}
    >
      {(component, componentProps) => (
        <AppContextProvider initialQueryRef={props.initialQueryRef}>
          {component && <AppToolbar />}
          {component && React.createElement(component, componentProps)}
        </AppContextProvider>
      )}
    </AppBase>
  );
};

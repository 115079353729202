const product = (product: { navName: string | null | undefined }) => {
  if (!product.navName) {
    console.error("product.navName is undefined");
    return "";
  }
  return "/product/" + product.navName;
};

const order = (
  order: { readableId: string | null | undefined },
  checkout = false,
) => {
  if (!order.readableId) {
    console.error("order.readableId is undefined");
    return "";
  }
  return `/order/${order.readableId}${checkout ? "?checkout=1" : ""}`;
};

export default { product, order };

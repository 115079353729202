import { Drawer, DrawerProps } from "@mui/material";
import React from "react";

export type NavigationDrawerProps = DrawerProps & {
  onClose: () => void;
};

export default function NavigationDrawer(props: NavigationDrawerProps) {
  const { onClose, children, ...drawerProps } = props;
  const onCloseDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" ||
        (event as React.KeyboardEvent).key === "Shift")
    ) {
      return;
    }

    onClose();
  };

  return (
    <>
      <Drawer anchor="left" onClose={onCloseDrawer} {...drawerProps}>
        {children}
      </Drawer>
    </>
  );
}

import { type Route } from "../core";
import RefererTreeQuery, {
  type RefererTreeQuery as RefererTreeQueryType,
} from "../queries/RefererTreeQuery.graphql";
import { type RefererTree } from "./RefererTree";

/**
 * Shows user's orders.
 */
export default {
  path: "/referers",
  query: RefererTreeQuery,
  component: () => import(/* webpackChunkName: "secondary" */ "./RefererTree"),
  response: (data) => {
    return {
      title: "Ваши рефералы",
      description: "Реферальное дерево ваших рефереров",
      props: { data },
    };
  },
} as Route<RefererTree, RefererTreeQueryType>;

import { Close } from "@mui/icons-material";
import { Menu, MenuItem, MenuProps } from "@mui/material";
import React from "react";
import { usePreloadedQuery } from "react-relay";
import { useAppContext } from "../components/AppContextProvider";
import { CatalogCategories } from "../components/CatalogCategories";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

type CatalogMenuProps = Omit<
  MenuProps,
  | "id"
  | "role"
  | "open"
  | "getContentAnchorEl"
  | "anchorOrigin"
  | "transformOrigin"
  | "onClose"
> & {
  onClose: () => void;
  gridMode: boolean;
};

function CatalogMenu(props: CatalogMenuProps): JSX.Element {
  const { gridMode, MenuListProps, onClose, ...other } = props;
  const appContext = useAppContext();
  if (!appContext.queryRef) {
    throw new Error("AppContextProviderQuery is not loaded!");
  }
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef,
  );
  return (
    <Menu
      id="catalog-menu"
      role="menu"
      open={Boolean(props.anchorEl)}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      slotProps={{
        paper: {
          sx: {
            maxWidth: "1100px",
            p: 2,
            position: { xs: "static", sm: "relative" },
          },
        },
      }}
      MenuListProps={{
        ...MenuListProps,
        dense: true,
        sx: { pt: 0 }, // Remove top padding from MenuList
      }}
      onClose={onClose}
      {...other}
    >
      <MenuItem
        sx={{
          justifyContent: "flex-end",
          pr: 1,
          position: "sticky",
          top: 0,
          bgcolor: "background.paper",
          zIndex: 1,
        }}
        onClick={onClose}
      >
        <Close fontSize="large" />
      </MenuItem>
      <CatalogCategories
        fragmentRef={appData}
        gridMode={gridMode}
        onClose={onClose}
      />
    </Menu>
  );
}

export { CatalogMenu, type CatalogMenuProps };

import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  FormControl,
  InputLabel,
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { ruRURub } from "dinno-util";
import React from "react";
import { graphql, useFragment } from "react-relay";
import nav from "../navigation";
import { ProductCard_product$key } from "../queries/ProductCard_product.graphql";
import { AddToCartControl } from "./AddToCartControl";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummy = React.StrictMode; // Prevents removal of React import

const productFragment = graphql`
  fragment ProductCard_product on Product {
    id
    name
    navName
    maker
    image
    unit
    unitPrice
    price
    isVoucher
    editions {
      id
      variantName
      navName
      maker
      image
      unit
      unitPrice
      price
    }
  }
`;

export const ProductCard = (props: { data: ProductCard_product$key }) => {
  const product = useFragment(productFragment, props.data);
  const productEditions = product.editions;
  const [productEditionId, setProductEditionId] = React.useState<string | null>(
    productEditions && (productEditions.length ?? 0) > 0
      ? (productEditions[0]?.navName ?? null)
      : (product.navName ?? null),
  );

  const productEditionsById = React.useMemo(() => {
    if (!productEditions || productEditions.length == 0)
      return new Map([[product.navName, product]]);
    return new Map(productEditions.map((pe) => [pe?.navName, pe]));
  }, [product, productEditions]);

  const productEdition = productEditionsById.get(productEditionId);

  const handleChangeEdition = (event: SelectChangeEvent) => {
    setProductEditionId(event.target.value);
  };

  return !productEditionId || !productEdition ? (
    <></>
  ) : (
    <Box sx={{ minWidth: 0, width: "100%", maxWidth: 340 }}>
      <Card>
        <Link href={nav.product({ navName: productEditionId })}>
          <CardHeader
            disableTypography
            title={
              <Typography
                variant="h6"
                component="h2"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
                  lineHeight: "2.5ex",
                  height: "5ex",
                  fontWeight: 600,
                }}
              >
                {product.name}
              </Typography>
            }
            subheader={
              <Typography
                variant="body2"
                sx={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical",
                  lineHeight: "2.5ex",
                  height: "2.5ex",
                  mt: 0.5,
                }}
              >
                {productEdition.maker ?? product.maker}
              </Typography>
            }
          />
          <CardMedia sx={{ textAlign: "center", verticalAlign: "center" }}>
            <Box
              component="img"
              width="100%"
              height={250}
              src={productEdition.image ?? product.image ?? ""}
              sx={{ objectFit: "cover" }}
              alt="Изображение товара"
            />
          </CardMedia>
        </Link>
        <CardContent
          sx={{ width: "100%", display: "flex", flexDirection: "column" }}
        >
          {productEditions?.length != 0 && (
            <Box sx={{ minWidth: 120, mb: 2 }}>
              <FormControl fullWidth>
                <InputLabel>Ассортимент</InputLabel>
                <Select
                  value={productEditionId}
                  label="Ассортимент"
                  onChange={handleChangeEdition}
                >
                  {!!productEditions &&
                    productEditions.map((pe) => (
                      <MenuItem
                        key={pe?.navName}
                        value={pe?.navName ?? undefined}
                      >
                        {pe?.variantName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Box>
          )}

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {(productEdition.unitPrice ?? product.unitPrice) &&
            (productEdition.unit ?? product.unit) ? (
              <Typography variant="body2" color="text.secondary" fontSize={15}>
                {ruRURub.format(
                  productEdition.unitPrice ?? product.unitPrice ?? 0,
                ) +
                  "/" +
                  (productEdition.unit ?? product.unit)}
              </Typography>
            ) : (
              <>&nbsp;</>
            )}
            <Typography variant="h5" component="div" color="text.primary">
              {ruRURub.format(productEdition.price ?? product.price ?? 0)}
            </Typography>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <AddToCartControl
            productId={productEdition.id}
            isVoucher={product.isVoucher ?? false}
          />
        </CardActions>
      </Card>
    </Box>
  );
};

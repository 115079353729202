import React from "react";
import { graphql, useFragment, usePreloadedQuery } from "react-relay";
import { MutationParameters } from "relay-runtime";
import { useAppContext } from "../components/AppContextProvider";
import {
  CommitPromiseResult,
  useMutationPromise,
} from "../core/mutationPromise";
import AppContextProviderQuery from "../queries/AppContextProviderQuery.graphql";
import CartFragment, {
  CartFragment$key,
} from "../queries/CartFragment.graphql";

const addToCartMutation = graphql`
  mutation addToCartCartControlAddtMutation($productId: ID) {
    addToCart(input: { productId: $productId }) {
      cart {
        ...CartFragment
      }
    }
  }
`;

export const useAddToCart = () => {
  const [commit] = useMutationPromise(addToCartMutation);
  const appContext = useAppContext();
  if (!appContext.queryRef) {
    throw new Error("AppContextProviderQuery is not loaded!");
  }
  const appData = usePreloadedQuery(
    AppContextProviderQuery,
    appContext.queryRef,
  );
  const cart = useFragment<CartFragment$key>(CartFragment, appData.cart);
  const hasNonVouchers = React.useMemo(
    () => cart?.items?.some((item) => !item?.product?.isVoucher),
    [cart],
  );
  const hasVouchers = React.useMemo(
    () => cart?.items?.some((item) => item?.product?.isVoucher),
    [cart],
  );
  return [
    React.useCallback(
      async function (productId: string) {
        return await commit({ variables: { productId } });
      },
      [commit],
    ),
    React.useCallback(
      function (isVoucher: boolean) {
        return (isVoucher && !hasNonVouchers) || (!isVoucher && !hasVouchers);
      },
      [hasNonVouchers, hasVouchers],
    ),
  ] as [
    (productId: string) => Promise<CommitPromiseResult<MutationParameters>>,
    (isVoucher: boolean) => boolean,
  ];
};

/**
 * @generated SignedSource<<f7abecf50452bd1242719456d144de67>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CatalogQuery$variables = {
  search: string;
  categorySlug?: string | null;
  productCursor?: string | null;
  numProducts?: number | null;
  breadcrumbHeadNavName?: string | null;
  breadcrumbHeadType?: string | null;
};
export type CatalogQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CatalogBreadcrumbsFragment" | "ProductListFragment" | "TopDiscountHintFragment">;
};
export type CatalogQuery = {
  variables: CatalogQuery$variables;
  response: CatalogQuery$data;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "breadcrumbHeadNavName"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "breadcrumbHeadType"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "categorySlug"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "numProducts"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productCursor"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "navName",
  "storageKey": null
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "productCursor"
  },
  {
    "kind": "Variable",
    "name": "categoryNavName",
    "variableName": "categorySlug"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "numProducts"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "maker",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "image",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unit",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "unitPrice",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "price",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CatalogQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "CatalogBreadcrumbsFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "ProductListFragment"
      },
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "TopDiscountHintFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "CatalogQuery",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "navName",
            "variableName": "breadcrumbHeadNavName"
          },
          {
            "kind": "Variable",
            "name": "type",
            "variableName": "breadcrumbHeadType"
          }
        ],
        "concreteType": "Category",
        "kind": "LinkedField",
        "name": "categoryPath",
        "plural": true,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "ProductConnection",
        "kind": "LinkedField",
        "name": "products",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ProductEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Product",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v14/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "isVoucher",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ProductEdition",
                    "kind": "LinkedField",
                    "name": "editions",
                    "plural": true,
                    "selections": [
                      (v6/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "variantName",
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v10/*: any*/),
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v13/*: any*/),
                      (v14/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "filters": [
          "search",
          "categoryNavName"
        ],
        "handle": "connection",
        "key": "ProductListFragment_products",
        "kind": "LinkedHandle",
        "name": "products"
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "RangeDiscount",
        "kind": "LinkedField",
        "name": "discounts",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "i",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "from",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "to",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "discount",
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserMAInfo",
            "kind": "LinkedField",
            "name": "maInfo",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCode",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "referralCnt",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "purchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "potentialPurchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "attractedPurchasesAmount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "PM",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "isInProgram",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasUnspentDiscount",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e987bdfee869b1f6133c8346e3a7b33",
    "id": null,
    "metadata": {},
    "name": "CatalogQuery",
    "operationKind": "query",
    "text": "query CatalogQuery(\n  $search: String!\n  $categorySlug: String\n  $productCursor: String\n  $numProducts: Int\n  $breadcrumbHeadNavName: String\n  $breadcrumbHeadType: String\n) {\n  ...CatalogBreadcrumbsFragment\n  ...ProductListFragment\n  ...TopDiscountHintFragment\n}\n\nfragment CatalogBreadcrumbsFragment on Query {\n  categoryPath(navName: $breadcrumbHeadNavName, type: $breadcrumbHeadType) {\n    id\n    name\n    navName\n    level\n  }\n}\n\nfragment ProductCard_product on Product {\n  id\n  name\n  navName\n  maker\n  image\n  unit\n  unitPrice\n  price\n  isVoucher\n  editions {\n    id\n    variantName\n    navName\n    maker\n    image\n    unit\n    unitPrice\n    price\n  }\n}\n\nfragment ProductListFragment on Query {\n  products(after: $productCursor, first: $numProducts, search: $search, categoryNavName: $categorySlug) {\n    edges {\n      node {\n        id\n        ...ProductCard_product\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      hasNextPage\n      endCursor\n    }\n  }\n}\n\nfragment TopDiscountHintFragment on Query {\n  discounts {\n    i\n    from\n    to\n    discount\n  }\n  me {\n    maInfo {\n      ...UserMAInfoFragment\n      id\n    }\n    id\n  }\n}\n\nfragment UserMAInfoFragment on UserMAInfo {\n  id\n  referralCode\n  referralId\n  referralCnt\n  purchasesAmount\n  potentialPurchasesAmount\n  attractedPurchasesAmount\n  PM\n  isInProgram\n  hasUnspentDiscount\n}\n"
  }
};
})();

(node as any).hash = "64fa71b4aa7290adf6eaf5cbac376818";

export default node;

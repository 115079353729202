import { Box, BoxProps, Button, Tooltip } from "@mui/material";
import React from "react";
import { useAddToCart } from "../common/addToCart";

export type AddToCartControlProps = BoxProps & {
  productId: string;
  isVoucher: boolean;
  buttonText?: string;
};

export const AddToCartControl = (props: AddToCartControlProps) => {
  const { productId, isVoucher, buttonText, ...boxProps } = props;

  const [addToCart, canAddToCart] = useAddToCart();
  const onClick = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      if (canAddToCart(isVoucher)) {
        await addToCart(productId);
      }
    },
    [productId, isVoucher, canAddToCart, addToCart],
  );
  return (
    <Box {...boxProps}>
      <Tooltip
        title={
          canAddToCart(isVoucher)
            ? "В корзину"
            : "Нельзя добавить больше одного вида товаров"
        }
      >
        <span>
          <Button
            variant="contained"
            color="secondary"
            onClick={onClick}
            disabled={!canAddToCart(isVoucher)}
          >
            {buttonText ?? "В корзину"}
          </Button>
        </span>
      </Tooltip>
    </Box>
  );
};
